import fetch from '../utilities/fetch'


const User = {
  getAll: (page = 1, order = null, sorter = null, search_text = null)  => fetch(`users?page=${page}&sorter=${sorter}&order=${order}&search=${search_text}`),
  me: ()              => fetch('users/me'),
  get:    (id)        => fetch(`users/${id}`),
  create: (body)      => fetch('register','POST',body),
  delete: (id)        => fetch(`users/${id}`,'DELETE'),
  disabled: (id,value)    => fetch(`users/disabled/${id}/${value}`,'PATCH'),
  update: (id,body)   => fetch(`users/${id}`,'POST',body),
  search: (text)      => fetch(`users?search=${text}`)

}

export default User
